<template>
  <div class="leaflet-product-container">
    <Breadcrumb class="d-none d-sm-flex px-0" :items="vBreadcrums" />
    <v-container v-if="leaflet">
      <div
        v-for="product in selectedProducts.data.products"
        :key="product.productId"
      >
        <h3>{{ product.name }}</h3>
        <ProductDetailLeaflet
          :product="product"
          :leaflet="leaflet"
          :popup="false"
          class="product-detail-card"
        /></div
    ></v-container>
  </div>
</template>
<style lang="scss">
.leaflet-product-container {
  .product-detail-card {
    border: 2px solid var(--v-grey-lighten3);
  }
}
</style>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import ProductDetailLeaflet from "@/components/leaflet/ProductDetailLeaflet.vue";
import get from "lodash/get";
import has from "lodash/has";
import leafletService from "~/service/leafletService";

export default {
  name: "LeafletProduct",
  components: {
    Breadcrumb,
    ProductDetailLeaflet
  },
  props: {
    productId: { type: String, required: true },
    leafletId: { type: String, required: true },
    page: { type: String, required: true }
  },
  data() {
    return {
      product: null,
      leaflet: null,
      selectedProducts: []
    };
  },
  computed: {
    vBreadcrums() {
      // let b = this.breadcrumb;
      let b = [];
      b.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      b.push({
        to: {
          name: "Leaflets"
        },
        text: "Volantini",
        exact: true
      });
      if (this.leaflet) {
        b.push({
          to: {
            name: "Leaflet",
            params: {
              volantino: this.$route.params.volantino
            }
          },
          text: this.leaflet.title,
          exact: true
        });
      }
      if (has(this.selectedProducts, "data.products[0].name")) {
        b.push({
          to: {
            name: "LeafletProduct"
          },
          text: this.selectedProducts.data.products[0].name
        });
      }
      return b;
    }
  },
  methods: {
    async openDetail() {
      this.selectedProducts = await leafletService.searchProducts({
        parent_leaflet_id: this.leafletId,
        parent_product_id: [this.productId],
        details: true
      });
    },
    async fetchLeaflet() {
      this.leaflet = await leafletService.getLeafletById(this.leafletId);
    }
  },
  metaInfo() {
    return {
      title: get(this.selectedProducts, "data.products[0].name"),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(this.selectedProducts, "data.products[0].description")
        }
      ]
    };
  },
  async mounted() {
    await this.openDetail();
    await this.fetchLeaflet();
  }
};
</script>
